// extracted by mini-css-extract-plugin
export var author = "BlogCard-module--author--9j3Rs";
export var authorBlock = "BlogCard-module--author-block--4S0C1";
export var authorImage = "BlogCard-module--author-image--AdAak";
export var blueSky = "BlogCard-module--blue-sky--b42Lo";
export var card = "BlogCard-module--card--HGaGG";
export var cardColour = "BlogCard-module--card-colour--5wPrJ";
export var cardFeatured = "BlogCard-module--card-featured--SRm83";
export var category = "BlogCard-module--category--5PVv-";
export var content = "BlogCard-module--content--LH7AJ";
export var evergreen = "BlogCard-module--evergreen--7dD6e";
export var featuredImage = "BlogCard-module--featured-image--CQ6lT";
export var junoRed = "BlogCard-module--juno-red--5pv6N";
export var postTitle = "BlogCard-module--post-title--0H9US";
export var readMore = "BlogCard-module--read-more--Pphyo";
export var sand = "BlogCard-module--sand--Noez-";
export var seaGlass = "BlogCard-module--sea-glass--X21KI";
export var stampWrapper = "BlogCard-module--stamp-wrapper--SljcV";
export var violetGray = "BlogCard-module--violet-gray--ya44z";